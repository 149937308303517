import { t, Trans } from '@lingui/macro';
import { Button } from '@we-make-websites/ui-lib/components';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import type { StoreLocale } from '@/root/constants';
import { localeToStore, opiChStore } from '@/root/constants';
import type { NewsletterFormInput } from 'components/route/NewsletterSignUp/types';
import {
  FormField,
  FormRadioButton,
  FormSelectField,
} from 'components/signUpForm/FormFields';
import { getPreferredLanguages } from 'components/signUpForm/SignUpForm';
import IconClose from 'icons/misc/close.svg';
import styles from '../signUpPopup.module.scss';

type SignUpProps = {
  signUpPopupFormPage: {
    title?: string;
    copy?: ReactNode | null;
    image?: {
      url: string;
    };
    termsAndConditionsUrl: string;
  };
  isLoading: boolean;
  error: string | null;
  onSubmit: (data: NewsletterFormInput) => void;
  locale: string;
  onCloseClick: () => void;
};

const SignUp = ({
  signUpPopupFormPage,
  isLoading,
  error,
  onSubmit,
  locale,
  onCloseClick,
}: SignUpProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<NewsletterFormInput>({ mode: 'onChange' });

  const onDateBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numbers = value.replace(/\D/g, '');
    let formattedValue = '';

    for (let i = 0; i < numbers.length; i++) {
      if (i === 2) {
        formattedValue += '-';
      }
      formattedValue += numbers[i];
    }

    setValue('dateOfBirth', formattedValue);
  };
  /* FIELDS DEFINITIONS */
  const firstNameField = (
    <FormField
      type="text"
      id="newsletter_sign_up_first_name"
      error={errors.firstName}
      label={t({
        id: 'sign_up_pop_up.first_name_label',
        message: 'First Name *',
      })}
      register={() =>
        register('firstName', {
          required: t({
            id: 'sign_up_pop_up.first_name_required',
            message: 'First Name is required',
          }),
        })
      }
    />
  );

  const lastNameField = (
    <FormField
      type="text"
      id="newsletter_sign_up_last_name"
      error={errors.lastName}
      className={styles.signUpPopup__lastNameField}
      label={t({
        id: 'sign_up_pop_up.last_name_label',
        message: 'Last Name *',
      })}
      register={() =>
        register('lastName', {
          required: t({
            id: 'sign_up_pop_up.last_name_required',
            message: 'Last Name is required',
          }),
        })
      }
    />
  );

  const emailField = (
    <FormField
      type="email"
      id="newsletter_sign_up_email"
      error={errors.email}
      label={t({
        id: 'sign_up_pop_up.email_label',
        message: 'Email Address *',
      })}
      register={() =>
        register('email', {
          required: t({
            id: 'sign_up_pop_up.email_required',
            message: 'Email is required',
          }),
        })
      }
    />
  );

  const preferredLanguageSelectField = (
    <FormSelectField
      id="registerPreferredLanguage"
      options={getPreferredLanguages(
        localeToStore(locale as StoreLocale) === opiChStore
      )}
      error={errors.preferredLanguage}
      required
      label={t({
        id: 'page.register.preferredLanguage',
        message: 'Preferred language',
      })}
      labelClass={styles.signUpPopup__preferredLanguageLabel}
      register={() =>
        register('preferredLanguage', {
          required: t({
            id: 'page.register.preferredLanguageRequired',
            message: 'Preferred language is required',
          }),
        })
      }
    />
  );

  const dateOfBirthField =
    locale === 'en-GB' ? (
      <FormField
        type="text"
        id="dateOfBirth"
        error={errors.dateOfBirth}
        label={t({
          id: 'sign_up_pop_up.date_of_birth_label',
          message: 'Date of birth (DD-MM)',
        })}
        maxLength={5}
        register={() =>
          register('dateOfBirth', {
            pattern: {
              value:
                /((0[1-9]|1\d|2\d|3[01])-(01|03|05|07|08|10|12)|(0[1-9]|1\d|2\d|30)-(04|06|09|11)|(0[1-9]|1\d|2\d)-02)/,
              message: t({
                id: 'page.register.dobError',
                message: 'Invalid date or format',
              }),
            },
            onChange: onDateBlur,
          })
        }
      />
    ) : null;
  /* END FIELDS DEFINITIONS */

  return (
    <div className={styles.signUpPopup}>
      <div className={styles.signUpPopup__formContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <h2>{signUpPopupFormPage.title}</h2>
            {signUpPopupFormPage.copy}
            <p className={styles.signUpPopup__radioDisclaimer}>
              <Trans id="sign_up_pop_up.radio_disclaimer">
                Choose what applies to you:
              </Trans>
            </p>
            <div
              className={styles.signUpPopup__radioContainer}
              role="radiogroup"
            >
              <FormRadioButton
                type="radio"
                id="customerType_consumer"
                name="customerType"
                className="radio__input"
                defaultChecked={true}
                label={t({
                  id: 'sign_up_pop_up.radio_consumer_label',
                  message: 'Consumer',
                })}
                value="subscriberCon"
                register={() => register('customerType')}
              />
              <FormRadioButton
                type="radio"
                id="customerType_professional"
                name="customerType"
                className="radio__input"
                label={t({
                  id: 'sign_up_pop_up.radio_pro_label',
                  message: 'Professional',
                })}
                value="subscriberPro"
                register={() => register('customerType')}
              />
            </div>
            <div className="form-group">
              {emailField}

              <div className={styles.signUpPopup__namesContainer}>
                {firstNameField}
                {lastNameField}
              </div>
              {/* preferred language is only asked on opi ch */}
              {localeToStore(locale as StoreLocale) === opiChStore ? (
                <div
                  className={clsx(
                    'form-group',
                    styles.signUpPopup__preferredLanguage
                  )}
                >
                  {preferredLanguageSelectField}
                </div>
              ) : null}
              {locale === 'en-GB' && (
                <p className={styles.signUpPopup__birthInputDisclaimer}>
                  <span
                    className={
                      styles.signUpPopup__birthInputDisclaimer__desktop
                    }
                  >
                    <Trans id="sign_up_pop_up.birth_input_disclaimer">
                      Enter your birthday so we can send you a surpise
                    </Trans>
                  </span>
                  <span
                    className={styles.signUpPopup__birthInputDisclaimer__mobile}
                  >
                    <Trans id="sign_up_pop_up.birth_input_disclaimer_mobile">
                      Enter your birthday to get a surprise
                    </Trans>
                  </span>
                </p>
              )}
              {locale === 'en-GB' && (
                <div
                  className={clsx(
                    'form-group',
                    styles.signUpPopup__dateOfBirthInput
                  )}
                >
                  {dateOfBirthField}
                </div>
              )}
            </div>
            <Button
              className={clsx(styles.signUpPopup__submit, {
                [styles.signUpPopup__submit__disabled]: isLoading,
              })}
              type={'submit'}
              disabled={isLoading}
              modifiers={['black', 'mini']}
            >
              <Trans id="sign_up_pop_up.submit">sign me up</Trans>
            </Button>
            {error && <p className={styles.signUpPopup__error}>{error}</p>}

            <p className={styles.signUpPopup__unsubribeDisclaimer}>
              <Trans id="sign_up_pop_up.unsubscribe_disclaimer">
                You can unsubscribe at any time. For more information on how we
                use your personal data please see our{' '}
                <a
                  target="_blank"
                  href="https://www.wellacompany.com/privacy-policy"
                  rel="noreferrer"
                  aria-label={t({
                    id: 'sign_up_pop_up.privacyPolicy',
                    message: 'Privacy Policy, opens a new tab',
                  })}
                >
                  Privacy Policy
                </a>
                . View our{' '}
                <a
                  target="_blank"
                  href={signUpPopupFormPage.termsAndConditionsUrl}
                  rel="noreferrer"
                  aria-label={t({
                    id: 'sign_up_pop_up.termsAndConditionsButton',
                    message: 'Terms and Conditions, opens a new tab',
                  })}
                >
                  Terms & Conditions
                </a>
                .
              </Trans>
            </p>
          </div>
        </form>
        <button
          className={styles.signUpPopup__closeButton}
          onClick={onCloseClick}
          disabled={isLoading}
        >
          <IconClose />
        </button>
        <div
          className={styles.signUpPopup__imageContainer}
          style={{
            backgroundImage: `url(${signUpPopupFormPage.image?.url})`,
          }}
        />
      </div>
    </div>
  );
};

export default SignUp;
