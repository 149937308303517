import { t } from '@lingui/macro';
import { useMutation } from '@tanstack/react-query';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useContextSelector } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import { newsletterFetcher } from 'utils/newsletter';
import { GlobalContentContext } from '../globalContentContext/GlobalContentContext';
import { OverlayContext } from '../overlay/OverlayContext';
import type { NewsletterFormInput } from '../route/NewsletterSignUp/types';
import SignUp from './components/signUp';
import SignUpConfirmed from './components/singUpConfirmed';

const Overlay = dynamic(() => import('components/overlay/Overlay'), {
  ssr: false,
});

export const POP_UP_NAMESPACE = 'marketing-popup';

enum SignUpPopupSteps {
  FORM = 'form',
  CONFIRMED = 'confirmed',
}

const setIsPopupSeenCookie = () => {
  const date = new Date();
  // set cookie for 30 days
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
  document.cookie = `marketingPopupSeen=true; expires=${date.toUTCString()}; path=/`;
};

// handles to exclude the popup
const exludedHandles = ['en-GB/pages/system-finder-quiz'];

const SignUpPopup = () => {
  const [step, setStep] = useState(SignUpPopupSteps.FORM);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const newsletterMutation = useMutation(newsletterFetcher);

  const openOverlay = useContextSelector(
    OverlayContext,
    (context) => context.open
  );
  const closeOverlay = useContextSelector(
    OverlayContext,
    (context) => context.close
  );
  const { signUpPopupFormPage, signUpConfirmedPopupFormPage } =
    useContextSelector(GlobalContentContext, (context) => ({
      signUpPopupFormPage: context.signUpPopupFormPage,
      signUpConfirmedPopupFormPage: context.signUpConfirmedPopupFormPage,
    }));
  const router = useRouter();

  const locale = storeLocale(router.locale);

  useEffect(() => {
    if (!signUpPopupFormPage || !signUpConfirmedPopupFormPage) {
      return;
    }

    function verifyCookie() {
      const marketingPopupCookie = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('marketingPopupSeen'));

      // check handles to exclude if any current href includes them
      if (
        exludedHandles.some((handle) => window.location.href.includes(handle))
      ) {
        return;
      }

      // verify if the user has seen the popup
      if (marketingPopupCookie) {
        return;
      }
      // look for onetrust consent in cookies
      const consent = document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('OptanonConsent'));

      // find in consent "groups" which is the key for the consent
      const groups = consent
        ?.split('&')
        .find((group) => group.includes('groups'))
        ?.split('=')[1];

      // decode uri component to get the actual value
      const decodedGroups = decodeURIComponent(groups || '')
        .split(',')
        .map((group) => group.split(':'));

      // check if C0002 has been accepted
      const isC0002Accepted = decodedGroups.some(
        ([group, val]) => group === 'C0002' && val === '1'
      );
      // if C0002 has not been accepted, do not show the popup
      if (!isC0002Accepted) {
        return;
      }

      // if the user has not seen the popup, show it after 3 seconds

      return true;
    }
    function onConsentChange() {
      if (verifyCookie()) {
        setTimeout(() => {
          openOverlay({ namespace: POP_UP_NAMESPACE });
        }, 3000);
      }
    }

    if (verifyCookie()) {
      const id = setTimeout(() => {
        openOverlay({ namespace: POP_UP_NAMESPACE });
      }, 3000);
      return () => clearTimeout(id);
    }

    window.addEventListener('OneTrustGroupsUpdated', onConsentChange);
    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', onConsentChange);
    };
  }, [signUpPopupFormPage, signUpConfirmedPopupFormPage]);

  const onCloseClick = () => {
    setIsPopupSeenCookie();
    closeOverlay(POP_UP_NAMESPACE);
  };

  if (!signUpPopupFormPage || !signUpConfirmedPopupFormPage) {
    return null;
  }

  const onSubmit: SubmitHandler<NewsletterFormInput> = async (data) => {
    setIsLoading(true);

    const months = data.dateOfBirth?.split('-')[1];
    const days = data.dateOfBirth?.split('-')[0];

    try {
      await newsletterMutation.mutateAsync({
        ...data,
        source: 'Z_SHOPIFY_POPUP',
        dateOfBirth: months && days ? `1900-${months}-${days}` : undefined, // dateOfBirth is in 1990-MM-DD format for the API
        locale,
      });
      setIsPopupSeenCookie();
      setStep(SignUpPopupSteps.CONFIRMED);
    } catch (error) {
      console.error(error);
      setError(t({ message: 'There was an error submitting the form' }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleShopNow = () => {
    router.push(signUpConfirmedPopupFormPage.toAddress ?? '/');
  };

  return (
    <Overlay
      type="modal"
      namespace={POP_UP_NAMESPACE}
      template={false}
      blockClass="signUpPopup"
    >
      {step === SignUpPopupSteps.CONFIRMED ? (
        <SignUpConfirmed
          {...{ handleShopNow, signUpConfirmedPopupFormPage, onCloseClick }}
        />
      ) : (
        <SignUp
          {...{
            signUpPopupFormPage,
            onCloseClick,
            isLoading,
            error,
            onSubmit,
            locale,
          }}
        />
      )}
    </Overlay>
  );
};

export default SignUpPopup;
